<template>
    <section class="info-container">
        <section class="info-item-wrap">
            <p class="info-title">里程碑节点</p>
            <a-row class="time-line-container">
                <a class="prev-nav-icon" >
                    <a-icon type="caret-left" @click="prev" />
                </a>
                <a class="next-nav-icon" >
                    <a-icon type="caret-right" @click="next" />
                </a>
                <a-row class="time-line"></a-row>
                <a-col class="item-wrap" v-for="item in pointList" :key="item.id" v-show="item.key> min && item.key < max">
                    <a-row v-if="item.id">
                        <a class="img-wrap">
                            <img @click="selectPoint(item.id)" :src="item.nodeUrl" alt="">
                        </a>
                        <div class="wrap-point">
                            <span :class="pointId == item.id ? 'active' : ''"></span>
                        </div>
                       
                        <a-row class="wrap-text">   
                            <p>{{item.name}}</p>
                            <p>
                                <span class="key">计划时间</span>
                                <span >{{item.startDate}}~{{item.endDate}}</span>
                            </p>
                            <p>
                                <span class="key">实际时间</span>
                                <span >{{item.actualStartDate}}~{{item.actualEndDate}}</span>
                            </p>
                        </a-row>
                    </a-row>
                </a-col>
            </a-row>
        </section>
        <section class="info-item-wrap m-t-18">
           <p class="info-title">
               <span>进度验收</span>
           </p>
            <section class="list-table-container m-t-18">
                <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{hideOnSinglePage:true}">
                    <a-row slot="actualStartDate" slot-scope="text,list">
                        <a-date-picker :disabled="list.status === 2 || list.status === 3" style="width:160px" :defaultValue="text" @change="e => changeActualStartDate(e,list)" />
                    </a-row>
                    <a-row slot="actualEndDate" slot-scope="text,list" type="flex">
                        <a-col style="width:180px"><a-date-picker :disabled="list.status === 2 || list.status === 3" style="width:160px" @change="e => changeActualEndDate(e,list)" :defaultValue="text" /></a-col>
                        <a-col><a-button v-if="list.status === 1 || list.status === 4" size="small" type="primary" @click="showSelectUser(list)">提交</a-button></a-col>
                    </a-row>
                    
                    
                    <span v-if="list.actualEndDate" slot="actualDay" :style="{color:text > 0 ? '#52C41A' : text == 0 ? '#1890ff' : '#FF555E'}" slot-scope="text,list">{{text > 0 ?  ('提前'+text+'天') : text == 0 ? '无' : ('滞后'+ Math.abs(text) +'天')}}</span>
                    <span slot="status" slot-scope="text">{{text === 1 ? '未提交' : text === 2 ? '审核中' : text === 3 ? '验收通过' : '验收驳回'}}</span>
                    <!-- <a v-if="list.status === 1" slot="action" @click="showProgressModal(list)" slot-scope="text,list">提交</a> -->
                </a-table>
            </section>
       </section>


       <!-- <a-modal centered width="660px" class="modal-container" :title="progressModal.title" v-model="progressModal.visible" :footer="false" :destroyOnClose="true">
            
           <a-form :form="form">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="实际开始时间">
                   <a-date-picker placeholder="请选择" v-decorator="['actualStartDate',{rules:[{required:true,message:'请选择实际开始时间'}]}]"  />
               </a-form-item>
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="实际结束时间">
                   <a-date-picker v-decorator="['actualEndDate',{rules:[{required:true,message:'请选择实际结束时间'}]}]" />
               </a-form-item>
                <a-form-item label="验收人" :label-col="{span:4}" :wrapper-col="{span:18}">
                        <select-user-cascader  @func="getUser" v-decorator="['acceptanceUser',{rules:[{required:true,message:'请选择验收人'}]}]" />
                    </a-form-item>
               <a-row class="form-btn-group">
                   <a-button class="btn-save" @click="submit"><a-icon type="save"  />保存</a-button>
                   <a-button class="btn-cancel"  @click="progressModal.visible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal> -->


         <a-modal width="1080px" class="modal-container" v-model="selectUserVisible" title="人员列表" :footer="false" :destroyOnClose="true" >
            <select-user :selectMode="'single'" @ok="submit" @cancel="selectUserVisible = false" />
        </a-modal>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'子里程碑',dataIndex:'sonName',align:'center',key:'sonName'},
    {title:'计划开始',dataIndex:'planStartDate',align:'center',key:'planStartDate'},
    {title:'计划完成',dataIndex:'planEndDate',align:'center',key:'planEndDate'},
    {title:'工期(天)',dataIndex:'planDay',align:'center',key:'planDay'},
    {title:'实际开始',dataIndex:'actualStartDate',align:'center',key:'actualStartDate',scopedSlots:{customRender: "actualStartDate"}},
    {title:'实际完成',dataIndex:'actualEndDate',align:'center',key:'actualEndDate',scopedSlots:{customRender: "actualEndDate"}},
    {title:'验收状态',dataIndex:'status',align:'center',key:'status',scopedSlots:{customRender: "status"}},
    {title:'偏差天数',dataIndex:'actualDay',align:'center',key:'actualDay',scopedSlots:{customRender: "actualDay"}},
    // {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
// import selectUserCascader from '@/components/select-user-cascader'
import selectUser from '@/components/selectUser'
import moment from 'moment'
export default {
    data(){
        return{
            columns,
            pointList:[],
            max:6,
            min:0,
            pointId:'',
            listMap:[],
            progressModal:{
                visible:false,
                title:'提交验收'
            },
            currentChildMilestone:{},
            actualStartDate:null,
            actualEndDate:null,
            selectUserVisible:false
           
        }
    },
    components:{selectUser},
    beforeCreate(){
         this.form = this.$form.createForm(this)
    },
    created() {
        this.queryMilestoneConfigList()
        //this.queryMilestoneSonList()
    },
    computed:{
        milestoneId(){  //里程碑id
            return this.$route.query.id
        },
        picServer(){
            return this.$store.state.picServer
        }
    },
    methods:{
        //里程碑节点
        queryMilestoneConfigList(){
            this.$api.queryMilestoneConfigList({milestoneId:this.milestoneId}).then(res =>{
                if(res.code === 200){
                    let pointList =  res.data || []
                    pointList.forEach((item,ind) =>{
                        item.key = ind+1
                        // item.actualStartDate = item.actualStartDate ? moment(item.actualStartDate) : null
                        // item.actualEndDate = item.actualEndDate ? moment(item.actualEndDate) : null
                        item.nodeUrl = item.nodeUrl ? this.picServer+item.nodeUrl : require('../../../../assets/image/icon/default-image.png')
                    })
                    this.pointId = this.pointId ? this.pointId : (pointList.length ? pointList[0].id : '')
                    
                    this.pointList = pointList
                    this.queryMilestoneSonList()
                }
            })
        },
        prev(){
            this.min = this.min > 0 ? this.min - 1 : 0
            this.max = this.min == 0 ? 6 : this.max -1
        },
        next(){
            let  l = this.pointList.length+1
            this.min = this.max == l ? l - 6 : this.min + 1
            this.max = this.max <= l ? this.min + 6 : l
        },
        selectPoint(id){
            this.pointId = id
           
            //this.listMap = []
            
            this.queryMilestoneSonList()
        },
        //子里程碑列表
        queryMilestoneSonList(){
            if(!this.pointId) return false
            this.$api.queryMilestoneSonList({configId:this.pointId}).then(res =>{
                if(res.code === 200){
                    this.listMap = res.data || []
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind +1
                        item.status = parseFloat(item.status)
                        item.actualDay = item.actualDay || 0
                        
                    })
                }
            })
        },
        
        
        changeActualStartDate(actualStartDate,list){
            this.actualStartDate = actualStartDate ? actualStartDate.format('YYYY-MM-DD') : ''
            this.currentChildMilestone = list
            this.updateMilestoneSon()
        },
        changeActualEndDate(actualEndDate,list){
            this.actualEndDate = actualEndDate ? actualEndDate.format('YYYY-MM-DD') : ''
            this.currentChildMilestone = list
            this.updateMilestoneSon()
        },
        updateMilestoneSon(){
            let formData = {
                actualStartDate:this.actualStartDate,
                actualEndDate:this.actualEndDate,
                id:this.currentChildMilestone.id
            }
            this.$api.updateMilestoneSon(formData).then(res =>{
                if(res.code === 200){
                    this.queryMilestoneConfigList()
                    this.progressModal.visible = false
                }
            })
        },
        showSelectUser(list){
            this.currentChildMilestone = list
            this.selectUserVisible = true
        },
        submit(userMap){
            if(!this.currentChildMilestone.actualStartDate){
                this.$message.warning('请选择实际开始时间')
                return false
            }
            if(!this.currentChildMilestone.actualEndDate){
                this.$message.warning('请选择实际结束时间')
                return false
            }
           
            let actualStartDate = this.currentChildMilestone.actualStartDate
            let actualEndDate = this.currentChildMilestone.actualEndDate
            //偏差天数
            //let actualDay = this.currentChildMilestone.planDay - moment(actualEndDate).diff(actualStartDate,'day')
            let actualDay = moment(this.currentChildMilestone.planEndDate).diff(actualEndDate,'day')
            let submitTime = moment().format('YYYY-MM-DD HH:mm:ss')
            let formData ={
                acceptanceUser:userMap[0].id,
                id:this.currentChildMilestone.id,
                status:2,
                actualDay,
                actualStartDate,
                actualEndDate,
                submitTime
            }
            
            this.$api.updateMilestoneSon(formData).then(res =>{
                if(res.code === 200){
                    this.queryMilestoneConfigList()
                    this.selectUserVisible = false
                }
            })   
        }
    }
}
</script>
