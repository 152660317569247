<template>
    <section>
        <a-row type="flex" class="select-user-page">
            
            <a-col class="company-list-wrap scroll-wrap">
                <a-input  placeholder="单位名称搜索" />
                <li @click="changeCompany(item)" :class="item.id == companyId ? 'active' : ''" v-for="(item,key) in companyLists" :key="key"><a><a-icon class="icon" type="home"/>{{item.companyName}}</a></li>
            </a-col>
            <a-col class="user-list-wrap">

                <a-row class="user-list scroll-wrap">
                    <a-row type="flex" :gutter="20">
                        <a-col class="m-b-10" :span="6" v-for="(item,key) in userList" :key="key">
                            <a-row class="list-item">
                                <a-checkbox class="list-check-box" v-model="item.checked" @change="e => selectUser(e,item)"></a-checkbox>
                                <div class="m-b-8">{{item.name}}</div>
                                    <span class="decs">{{item.jobsName}}</span>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-row>
                <a-row class="select-user-list">
                    <a-row type="flex" :gutter="20">
                        <a-col class="m-b-10" :span="6" v-for="(item,key) in selectedUserList" :key="key">
                            <a-row class="list-item">
                                <div class="m-b-8">{{item.name}}</div>
                                <span class="decs">{{item.jobsName}}</span>
                                <a class="close-icon" title="删除" @click="item.checked = false"><a-icon type="close"></a-icon></a>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-row>
                <section class="btn-group form-btn-group">
                <a-button class="m-r-10" @click="save">保存</a-button>
                <a-button @click="cancel">取消</a-button>
            </section>
            </a-col>
            
        </a-row>
    </section>
</template>


<script>
export default {
    data(){
        return{
            companyLists:[],
            companyId:'',
            userList:[],
            selectedUserList:[]
        }
    },
    props:['selectMode'],
    created(){
        this.queryUserOptionsDialog()
    },
    computed:{
        // selectedUserList() {
        //     let r = [];
        //     this.companyLists.forEach(item => {
        //         if (item.user && item.user.length) {
        //         item.user.forEach(v => {
        //             if (v.checked) {
        //                 r.push(v);
        //             }
        //         });
        //         }
        //     });
        //     return r;
        // }
    },
    methods:{
        queryUserOptionsDialog(){
            this.$api.queryUserOptionsDialog().then(res =>{
                if(res.code === 200){
                    let companyLists = res.data || []
                    companyLists.forEach(item => {
                        if (item.user && item.user.length) {
                        item.user.forEach(v => {
                            v.checked = false;
                        });
                        }
                    });
                    this.companyLists = companyLists
                    this.companyId = this.companyLists.length ? this.companyLists[0].id : ''
                     this.userList = this.companyLists.length
                    ? this.companyLists[0].user
                    : [];
                }
            })
        },
        selectUser(e,list){
            
            if(this.selectMode === 'single'){
                this.companyLists.forEach(item => {
                    if (item.user && item.user.length) {
                        item.user.forEach(v => {
                            v.checked = false
                            if (v.id === list.id) {
                               v.checked = e.target.checked
                            }
                        });
                    }
                });
                this.selectedUserList = list.checked ? [list] : []
            }else{
                let r = [];
                this.companyLists.forEach(item => {
                    if (item.user && item.user.length) {
                        item.user.forEach(v => {
                            if (v.checked) {
                                r.push(v);
                            }
                        });
                    }
                });
                this.selectedUserList = r 
            }
            
        },
        changeCompany(item){
            this.companyId = item.id
            this.userList = item.user || []
        },
         save() {
             if(this.selectedUserList.length){
                 this.$emit("ok", this.selectedUserList);
             }else{
                 this.$message.warning('请选择人员')
             }
            
        },
        cancel() {
            this.$emit("cancel", []);
        }
    }
}
</script>

<style lang="scss" scoped>
    .select-user-page{
        
        height: 500px;
        .scroll-wrap{
            overflow-y: auto;
            overflow-x: hidden;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .company-list-wrap{
            width:220px;
            height: 100%;
           
            border-right: 2px dotted rgba(255,255,255,0.2);
            li{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: 4px 8px 4px 0;
                .icon{
                    margin-right: 6px;
                    font-size: 14px;
                }
                a{
                    color: rgba(255,255,255,0.8);
                }
                &:hover{
                    a{
                        color:rgba(76, 132, 211,1)
                    }
                }
                
            }
            .active{
                    a{
                        color:rgba(76, 132, 211,1)
                    }
                }
        }
         .user-list-wrap {
    height: 100%;
    width: calc(100% - 220px);
    padding: 0 0 0 10px;
    .user-list {
      height: 55%;
      padding-left: 10px;
      border-bottom: 1px dotted rgba(76, 132, 211, 0.5);
    }
    .select-user-list {
      height: 40%;
      padding: 10px 0 10px 10px;

      .list-item {
        padding: 8px 10px 10px 24px;
        .close-icon{
            position: absolute;
            top:-8px;
            right:-8px;
            width:20px;
            height: 20px;
            font-size: 10px;
            background: rgba(22,22,22,0.8);
            color: #FFFFFF;
            z-index:10;
            border-radius: 100%;
            text-align: center;
            display: none;
        }
        &:hover{
          .close-icon{
            display: block;
          }
        }
      }
    }
    .list-item {
      position: relative;
      border: 1px solid rgba(76, 132, 211, 0.5);
      padding: 8px 10px 10px 44px;
      border-radius: 4px;
      cursor: pointer;
      .decs {
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
      }
      .list-check-box {
        position: absolute;
        top: 8px;
        left: 14px;
      }
    }
  }
        .btn-group {
            position: absolute;
            bottom: 0px;
            right: -20px;
            display: inline-block;
        }
    }
    
</style>